<div *ngIf="isOpen" class="flex-column kukai-modal">
  <div class="content">
    <div class="flex-row top">
      <div class="flex-row button close" (click)="close()"><img class="x"
          src="../../../../../assets/img/close-btn-x.png" />
      </div>
      <h1 class="title">Token Info</h1>
    </div>
    <div class="flex-column token-info">
      <div class="flex-row-wrap data">
        <div class="flex-column wrapper" [class]="imageExpanded ? 'expanded' : ''">
          <app-asset [style.display]="imageExpanded ? 'none' : ''" class="flex-row button sd-only"
            [priorityList]="['displayAsset', 'thumbnailAsset']"
            [assets]="{thumbnailAsset: token?.thumbnailAsset, displayAsset: token?.displayAsset}" [size]="'small'"
            (click)="expandImage()" (loaded)="assetLoaded = true" [autoplay]="true" [muted]="true" [loop]="true" [playsinline]="true"></app-asset>
          <app-asset [style.display]="!imageExpanded ? 'none' : ''" class="flex-row button sd-only"
            [priorityList]="['artifactAsset', 'displayAsset', 'thumbnailAsset']"
            [assets]="{thumbnailAsset: token?.thumbnailAsset, displayAsset: token?.displayAsset, artifactAsset: token?.artifactAsset}"
            [size]="'raw'" [poster]="token?.displayAsset" [controls]="true"></app-asset>
          <img *ngIf="imageExpanded" class="flex-row button close" (click)="expandImage()"
            src="../../../../../assets/img/close-btn-x.png" />
          <img *ngIf="!imageExpanded && assetLoaded && !token.displayAsset?.mimeType?.startsWith('model/')"
            class="expand" src="../../../../../assets/img/expand.png" (click)="expandImage()" />
          <b *ngIf="!imageExpanded && token.artifactAsset?.mimeType?.startsWith('model/')" class="expand icon-threed"
            (click)="expandImage()">3D</b>
        </div>
        <div class="flex-column meta">
          <div class="flex-column top">
            <div *ngIf="token?.name" class="flex-column key-value">
              <div class="key">
                name
              </div>
              <div class="value">
                {{ token?.name }}
              </div>
            </div>
            <div *ngIf="!isNFT && token?.symbol" class="flex-column key-value">
              <div class="key">
                symbol
              </div>
              <div class="value">
                {{ token?.symbol }}
              </div>
            </div>
            <div *ngIf="!isNFT && !!token?.decimals" class="flex-column key-value">
              <div class="key">
                decimals
              </div>
              <div class="value">
                {{ token?.decimals }}
              </div>
            </div>
            <div *ngIf="token?.description" class="flex-column key-value desc">
              <div class="key">
                description
              </div>
              <div class="value" style="white-space: pre-line">
                {{token?.description}}
              </div>
            </div>
            <div *ngIf="token?.creator" class="flex-column key-value">
              <div class="key">
                creator
              </div>
              <div class="value">
                {{ token?.creator }}
              </div>
            </div>
          </div>
        </div>
        <app-asset [style.height]="isAudio ? '' : '0px'" class="flex-row audio minimal-controls" [requires]="['audio']"
          [assets]="token" [priorityList]="['artifactAsset']" [size]="'raw'" [autoplay]="true" [muted]="true"
          [hideSpinner]="true" [controls]="true" (load)="isAudio = true"></app-asset>
      </div>
      <ng-container *ngFor="let type of Object.keys(CONSTANTS.FEATURE_CONTRACTS)">
        <ng-container *ngFor="let feat of Object.keys(CONSTANTS.FEATURE_CONTRACTS[type])">
          <app-ui-toggle-advanced
            *ngIf="CONSTANTS.FEATURE_CONTRACTS[type][feat]?.includes(token?.contractAddress + ':' + token?.id)"
            [class]="'feat ' + feat" style="width: 100%" (stateChange)="toggleFeature(type, feat)"
            [text]="(feat + ' Mode').toUpperCase()" [state]="theme === feat" [default]="theme === feat">
          </app-ui-toggle-advanced>
        </ng-container>
      </ng-container>
      <div *ngIf="!!token" class="flex-row button more-info" [class]="moreInfo ? 'open' : ''" (click)="expand()">
        <span>{{ moreInfo ? 'LESS' : 'MORE' }} INFO</span><img src="../../../assets/img/chevron-down.svg" />
      </div>
      <div *ngIf="!!token" [style.maxHeight]="moreInfo ? (3.25 * Object.keys(tokenFiltered).length) + 'rem' : ''"
        [style.overflowY]="autoOverflow ? 'auto' : ''" class="flex-column meta-more">
        <ng-container *ngFor="let key of Object.keys(tokenFiltered)">
          <div *ngIf="key === 'contractAddress'" class="flex-column key-value">
            <div class="key">
              {{ 'CONTRACT (ID)' }}
            </div>
            <div class="value">
              {{ token[key] + ' ' + '(' + token['id'] + ')' }}
            </div>
          </div>
          <div *ngIf="key !== 'contractAddress' && key !== 'description' && key !== 'id' && !!token[key]"
            class="flex-column key-value">
            <div class="key">
              {{key}}
            </div>
            <div class="value">
              {{ token[key] }}
            </div>
          </div>
        </ng-container>
      </div>
      <div *ngIf="!token?.isUnknownToken" class="flex-column bottom">
        <div class="seperator"></div>
        <app-send-button class="send" [override]="true" [activeAccount]="activeAccount"
          [tokenTransfer]="!token ? null : token['contractAddress']+':'+token['id']"
          [symbol]="!token ? 'tez' : token?.symbol">
        </app-send-button>
      </div>
    </div>
  </div>
</div>