<ng-container *ngIf="this.display === Display.image || (this.display === Display.audio && !!poster)">
  <!-- Need crossorigin anonymous here to leverage caching from first GET (check mime type) -->
  <img crossorigin="anonymous" #postImage [src]="postSrc" id="postImage" [style.objectFit]="postSrc?.indexOf(loaderUrl) > -1 ? 'scale-down' : ''" (error)="onError($event)" (load)="onLoad($event)" />
  <img crossorigin="anonymous" #preImage [src]="preSrc" id="preImage" (error)="onError($event)" (load)="onLoad($event)" />
</ng-container>
<audio crossorigin="anonymous" #audio *ngIf="this.display === Display.audio" preload="metadata" [controls]="controls" [controlsList]="controlsList" id="audio" (error)="onError($event)" (loadeddata)="onLoadData($event)">
  <source crossorigin="anonymous" [src]="dataSrc" [type]="mimeType" />
</audio>
<video crossorigin="anonymous" #video *ngIf="this.display === Display.video" preload="metadata" id="video" [controls]="controls" [playsinline]="playsinline" [muted]="muted" [loop]="loop" [autoplay]="autoplay" (error)="onError($event)" (loadeddata)="onLoadData($event)">
  <source crossorigin="anonymous" [src]="dataSrc" [type]="mimeType" />
  <img crossorigin="anonymous" [src]="loaderUrl" class="spinner" />
</video>
<div *ngIf="this.display === Display.threeD" class="model-wrapper">
  <model-viewer crossorigin="anonymous" #model id="modelCanvas" auto-rotate rotation-per-second="30deg"
    [src]="dataSrc" alt="postSrc" (load)="onLoad($event)" (error)="onError($event)"
    camera-controls ar ar-modes="webxr scene-viewer quick-look"></model-viewer>
</div>