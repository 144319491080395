<form autocomplete="off">
  <div class="flex-column card">
    <a routerLink="/"><button class="flex-row back"><img
          src="../../../../../assets/img/left-arrow.svg">BACK</button></a>
    <div *ngIf="activePanel==1" class="flex-column body">
      <h1>Back up your seed</h1>
      <p class="description">When you create a new wallet, new Tezos Recovery (Seed) Words are generated.<br />Your seed
        words are
        the
        master key of your wallet accounts and any value they hold.</p>
      <div class="whitebox" (mouseout)="mouseOut($event)" (touchstart)="$event.stopPropagation(); this.hideBlur = true;"
        (touchend)="checkSelection($event)" (click)="$event.stopPropagation()" (mouseup)="checkSelection($event)">
        {{ MNEMONIC.string }}<div *ngIf="!hideBlur" class="flex-row seed-blur"
          (click)="$event.stopPropagation(); this.hideBlur = true;">
          <span>CLICK HERE TO REVEAL YOUR SEED WORDS</span>
        </div>
      </div>
      <p class="description red">
        <span *ngIf="isSelectedMnemonic" style="margin-top: -4rem" class="warning red">
          WARNING: Copying the seed words to your clipboard can result in sensitive leakage. The clipboard is a shared
          resource that can be monitored by other web pages or browser extensions.<br />
        </span> Be sure sure to back it up (seed words), write it down, and keep it incredibly safe.
      </p>
      <button class="button next" (click)="verifyView()">Next</button>
    </div>
    <div *ngIf="activePanel==2" class="flex-column body">
      <h1>Verify your seed</h1>
      <p class="description">{{ MNEMONIC.verify.length ? 'Fill in the ' + formatVerifyDescription(MNEMONIC.verify[0] +
        1)
        + ' to verify
        your seed backup' : 'Recovery Words (seed) have been verified' }}</p>
      <progress max="{{ MNEMONIC.wordsToVerify }}"
        value="{{ MNEMONIC.wordsToVerify - MNEMONIC.verify.length }}"></progress>
      <div *ngIf="MNEMONIC.verify.length" class="flex-row word-verify">
        <div class="flex-column word-hint">
          <span class="word-index">{{ indexFormat(MNEMONIC.verify[0] - 1) }}</span>
          <span class="word-value"><b>{{ valueFormat(MNEMONIC.verify[0] - 1) }}</b></span>
        </div>
        <div class="flex-column word-input">
          <span class="word-index">
            <ng-container *ngIf="MNEMONIC.verify.length">Word {{ MNEMONIC.verify[0] + 1 }}</ng-container>
          </span>
          <input id="wordInput" class="text" name="none" type="text" (input)="checkWord()" (change)="checkWord()"
            [(ngModel)]="wordInput" [disabled]="!MNEMONIC.verify.length" autofocus>
        </div>
        <div class="flex-column word-hint">
          <span class="word-index">{{ indexFormat(MNEMONIC.verify[0] + 1) }}</span>
          <span class="word-value"><b>{{ valueFormat(MNEMONIC.verify[0] + 1) }}</b></span>
        </div>
      </div>
      <button *ngIf="!MNEMONIC.verify.length" class="button next" (click)="pwdView()">Next</button>
    </div>
    <div *ngIf="activePanel==3" class="flex-column body">
      <h1>Set a password</h1>
      <p class="description bold blue">This password will be used to create an encrypted keystore file.</p>
      <p class="description">When performing operations that need to be signed with your
        secret key, this password will be required</p>
      <input name="none" type="password" placeholder="{{ 'NEWWALLETCOMPONENT.PASSWORD' | translate }}" class="text"
        autocomplete="new-password" [(ngModel)]="pwd1" (keyup)="calcStrength()">
      <small class="strength"> {{ 'NEWWALLETCOMPONENT.PASSWORDSTRENGTH' | translate }} — <b
          [style.color]="pwdStrength === 'Catastrophic!' ? 'darkred' : pwdStrength === 'Very weak!' ? 'red' : pwdStrength === 'Weak!' ? 'orange' : pwdStrength === 'Strong!' ? 'green' : 'red'">{{
          pwdStrength }}</b></small>
      <input name="none" type="password" placeholder="{{ 'NEWWALLETCOMPONENT.CONFIRMPASSWORD' | translate }}" class="text"
        autocomplete="new-password" [(ngModel)]="pwd2">
      <button class="button next" [style.visibility]="!pwd1 ? 'hidden' : ''" (click)="encryptWallet()">Next</button>
    </div>
    <div *ngIf="activePanel==4" class="flex-column body">
      <h1>Wallet created!</h1>
      <p class="description bold">Your wallet is now set up and ready to be used.</p>
      <p class="description">Download your encrypted keystore file and import it when you want to access your wallet.
      </p>
      <div class="whitebox final">
        <p class="description bold pkh">Your public account address:<br />{{ getPkh() }}</p>
      </div>
      <button class="button next" (click)="download()">Download</button>
      <button class="button next" [style.visibility]="!ekfDownloaded ? 'hidden' : ''" (click)="done()">Open
        Wallet</button>
    </div>
    <span class="flex-row step-counter">
      <ng-container *ngFor="let _ of [1, 2, 3, 4]">
        <div class="dot" [class]="_ === activePanel ? 'selected' : ''"></div>
      </ng-container>
    </span>
  </div>
</form>