<div *ngIf="isOpen" class="flex-column kukai-modal">
  <div class="content">
    <div class="flex-row top">
      <div class="flex-row button close" (click)="closeModalAction()"><img class="x"
          src="../../../assets/img/close-btn-x.png" /></div>
      <h1 class="title">Originate</h1>
    </div>
    <div class="seperator"></div>
    <div class="flex-column originate">
      <div class="flex-column top">
        <span class="amount key">AMOUNT:&nbsp;<span class="value">{{ this.balance ? this.balance + ' ' + 'tez' : ''
            }}</span></span>
        <!-- <span class="amount-usd"> </span> -->
        <span class="section key">From:&nbsp;<span class="from">{{ externalRequest?.selectedAccount?.address }}</span></span>
      </div>
      <br />
      <div class="flex-row attrs">
        <div class="flex-row attr">
          <span class="key">FEE:&nbsp;</span><span class="value">{{ this.getTotalFee() }} tez</span>
        </div>
        <div class="flex-row attr">
          <span class="key">MAX STORAGE COST:&nbsp;</span><span class="value">{{ getTotalBurn() }} tez</span>
        </div>
      </div>
      <app-ui-toggle-advanced style="width: calc(100% - 1em);margin: 2em 0;" [default]="false"
        (stateChange)="advancedForm = $event">
      </app-ui-toggle-advanced>
      <form autocomplete="off" *ngIf="advancedForm" class="advanced-form">
        <div class="tabs-container">
          <div class="flex-row tabs">
            <span class="tab" [class.active]="activeTab === 0" (click)="activeTab = 0">Code</span>
            <span class="tab" [class.active]="activeTab === 1" (click)="activeTab = 1">Storage</span>
          </div>
        </div>
        <textarea rows="10" readonly>{{ getScript() }}</textarea>
        <ng-container *ngIf="!(walletService.isLedgerWallet() && !ledgerError)">
          <div class="flex-row">
            <div class="flex-column">
              <label class="label">Gas limit</label>
              <input numericinput="decimal" name="none" type="text" class="text" placeholder={{this.defaultTransactionParams.gas.toString()}}
                [(ngModel)]="customGas">
            </div>
            <div class="flex-column">
              <label class="label">Fee</label>
              <input numericinput="decimal" name="none" type="text" class="text" (keypress)="keyPressNumbersDecimal($event, 'customFee')"
                placeholder={{this.defaultTransactionParams.fee.toString()}} [(ngModel)]="customFee">
            </div>
          </div>
          <div class="flex-row">
            <div class="flex-column">
              <label class="label">Storage limit</label>
              <input numericinput="decimal" name="none" type="text" class="text"
                placeholder={{this.defaultTransactionParams?.storage.toString()}} [(ngModel)]="customStorage">
            </div>
            <div class="flex-column">
              <label class="label">Max storage cost</label>
              <input numericinput="decimal" name="none" type="text" class="text read-only" [value]="burnAmount() ? burnAmount() : '0 tez'"
                tabindex=-1 readonly>
            </div>
          </div>
        </ng-container>
      </form>
      <span *ngIf="formInvalid" class="danger margin">
        {{ formInvalid }}
      </span>
      <div class="flex-column bottom">
        <div class="seperator"></div>
        <br />
        <ng-container *ngIf="walletService.isPwdWallet()">
          <input name="none" [(ngModel)]="password" placeholder="Password" autocomplete="current-password"
            class="text password" type="password" (input)="this.pwdInvalid = ''" (paste)="this.pwdInvalid = ''"
            (keydown.enter)='!messageService.spinnerOn ? inject() : null'>
        </ng-container>
        <button *ngIf="walletService.isLedgerWallet() && ledgerError; else elseBlock" class="blue confirm"
          (click)="ledgerSign()">Sign with Ledger</button>
        <ng-template #elseBlock>
          <button class="blue confirm" (click)="!messageService.spinnerOn ? inject() : null"
            [disabled]="(walletService.isLedgerWallet() && (!sendResponse || !sendResponse.payload || !sendResponse.payload.signedOperation)) || walletService.isWatchWallet()">
            {{ walletService.isLedgerWallet() ? 'Broadcast' : 'Confirm'}}</button>
        </ng-template>
      </div>
      <span *ngIf="this.pwdInvalid" class="danger margin">{{ this.pwdInvalid }}</span>
    </div>
  </div>
</div>